var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-content',{attrs:{"loading":_vm.loading,"id":"baseContentTop"},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.content)?_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.content[0].title))])])],1)],1):_vm._e()]},proxy:true},{key:"content",fn:function(){return [(_vm.content)?_c('v-container',{attrs:{"id":"scrollArea","fluid":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":_vm.dynamicFlex(),"order-md":"1","order":"2","order-sm":"2"}},[(_vm.content[0].content)?_c('div',{staticClass:"dynamic-content",domProps:{"innerHTML":_vm._s(_vm.renderToHtml(_vm.content[0].content))},on:{"click":_vm.handleClicks}}):_vm._e(),_c('p',[_vm._v(" For meeting materials prior to 2019, please see "),_c('a',{attrs:{"href":"https://archive.icjia.cloud/files/spac/"}},[_vm._v("the SPAC archives")]),_vm._v(". ")]),_c('toggle',{staticClass:"mt-10",attrs:{"toggleOn":"By Category","toggleOff":"By Date","name":"meetings"}}),(_vm.displayMode.message === 'By Category')?_c('div',_vm._l((_vm.$store.getters.config.strapiEnums.meetings),function(category){return _c('div',{key:category.enum,staticClass:"mb-12"},[_c('h2',{attrs:{"id":category.slug}},[_vm._v(_vm._s(category.title))]),(category.description)?_c('p',{staticClass:"dynamic-content",domProps:{"innerHTML":_vm._s(category.description)},on:{"click":_vm.handleClicks}}):_vm._e(),_c('ListTableMeeting',{staticClass:"mt-8",class:{
                    'pl-6':
                      _vm.$vuetify.breakpoint.md ||
                      _vm.$vuetify.breakpoint.lg ||
                      _vm.$vuetify.breakpoint.xl,
                    'pr-6':
                      _vm.$vuetify.breakpoint.md ||
                      _vm.$vuetify.breakpoint.lg ||
                      _vm.$vuetify.breakpoint.xl
                  },attrs:{"items":_vm.filterMeetingData(category.enum),"hideCategory":true}})],1)}),0):_vm._e(),(_vm.displayMode.message === 'By Date')?_c('div',[_c('ListTableMeeting',{staticClass:"mt-8",attrs:{"items":_vm.meetings,"hideCategory":false}})],1):_vm._e()],1),(_vm.displayMode.message === 'By Category')?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2","order-md":"2","order":"1","order-sm":"1"}},[_c('TOC',{attrs:{"selector":"#scrollArea","top":"#baseContentTop","tocHeading":"Categories"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v(" For meeting materials prior to 2019, please see "),_c('a',{attrs:{"href":"https://archive.icjia.cloud/files/spac/"}},[_vm._v("the SPAC archives")]),_vm._v(". ")])])],1)],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }